import React from 'react';
import classes from "./info.module.css";

const Info = () => {
    return (
        <div className={classes._}>
            <a className={classes.link} href={'/oferta.docx'}>Публичная оферта</a>
            <div>Остались вопросы? Напиши нам: <a className={classes.link} href={'https://t.me/easyfastvpn'}>@easyfastvpn</a></div>
            <div>Семенова Светлана Валерьевна ИНН 344716017214</div>
        </div>
    );
};

export default Info;