import React, {useEffect, useRef, useState} from 'react';
import classes from "./map.module.css";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {getLocation} from "../../utils/location";
import {getServers} from "../../utils/api";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map = () => {
    const [map, setMap] = useState()
    mapboxgl.accessToken = 'pk.eyJ1Ijoiam9objk1NzciLCJhIjoiY20zYm9zaWMzMXB1NDJxcXdpcmRzZTk2ciJ9.h9vch969ZE6RFSfTsKd_aQ';

    const mapNode = useRef(null);

    function drawLocation(mapboxMap, location){
        const size = 50;

        // This implements `StyleImageInterface`
        // to draw a pulsing dot icon on the map.
        const pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map,
            // get the rendering context for the map canvas.
            onAdd: function () {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            // Call once before every frame where the icon will be used.
            render: function () {
                const duration = 1000;
                const t = (performance.now() % duration) / duration;

                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * t + radius;
                const context = this.context;

                // Draw the outer circle.
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = `rgba(255, 100, 255, ${1 - t})`;
                context.fill();

                // Draw the inner circle.
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = 'rgba(255, 100, 255, 1)';
                context.strokeStyle = 'white';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                // Update this images's data with data from the canvas.
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;

                // Continuously repaint the map, resulting
                // in the smooth animation of the dot.
                mapboxMap.triggerRepaint();

                // Return `true` to let the map know that the images was updated.
                return true;
            }
        };

        /*mapboxMap.on('load', () => {
            mapboxMap.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

            mapboxMap.addSource('dot-point', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': [
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [location.longitude, location.latitude] // icon position [lng, lat]
                            }
                        }
                    ]
                }
            });
            mapboxMap.addLayer({
                'id': 'layer-with-pulsing-dot',
                'type': 'symbol',
                'source': 'dot-point',
                'layout': {
                    'icon-images': 'pulsing-dot'
                }
            });
        });*/

        const marker = new mapboxgl.Marker({ color: 'red'})
            .setLngLat([location.longitude, location.latitude])
            .addTo(mapboxMap);
    }

    function drawServers(mapboxMap, servers){
        const size = 50;

        // This implements `StyleImageInterface`
        // to draw a pulsing dot icon on the map.
        const pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map,
            // get the rendering context for the map canvas.
            onAdd: function () {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            // Call once before every frame where the icon will be used.
            render: function () {
                const duration = 1000;
                const t = (performance.now() % duration) / duration;

                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * t + radius;
                const context = this.context;

                // Draw the outer circle.
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = `rgba(255, 200, 255, ${1 - t})`;
                context.fill();

                // Draw the inner circle.
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = 'rgba(255, 100, 255, 1)';
                context.strokeStyle = 'white';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                // Update this images's data with data from the canvas.
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;

                // Continuously repaint the map, resulting
                // in the smooth animation of the dot.
                mapboxMap.triggerRepaint();

                // Return `true` to let the map know that the images was updated.
                return true;
            }
        };
        mapboxMap.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
        const features = []
        servers.forEach(item => {
            features.push(
                {
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': [item.longitude, item.latitude] // icon position [lng, lat]
                }
            })
        })
        mapboxMap.on('load', () => {
            mapboxMap.addSource('dot-point', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': features
                }
            });
            mapboxMap.addLayer({
                'id': 'layer-with-pulsing-dot',
                'type': 'symbol',
                'source': 'dot-point',
                'layout': {
                    'icon-image': 'pulsing-dot',
                    'icon-allow-overlap': true,
                }
            })
        });
    }

    useEffect(()=>{
        const getLocationsT = async () =>{
            const location = await getLocation();
            const servers = await getServers();
            if (typeof window === "undefined" || mapNode.current === null) return;
            const mapboxMap = new mapboxgl.Map({
                container: mapNode.current,
                style: "mapbox://styles/john9577/clhslowk401w201r0ci5e87ak",
                center: { lng: 0, lat: 15 },
                zoom: 1.1,
                antialias: true,
                projection: 'naturalEarth'
            });
            mapboxMap.scrollZoom.disable();
            mapboxMap.dragRotate.disable();
            mapboxMap.touchZoomRotate.disableRotation();
            drawServers(mapboxMap, servers.res);
            drawLocation(mapboxMap, location);

            //setMap(mapboxMap)
            //return () => {
            //    mapboxMap.remove();
            //};
        }
        getLocationsT();
    },[])

    return (
        <div ref={mapNode} className={classes._}>
            <div className={classes.info_block}>
                <div className={classes.info_block_block}><div className={classes.location_icon}><div className={classes.location_icon_center}></div></div>Ваше расположение</div>
                <div className={classes.info_block_block}><div className={classes.server_icon}></div>Расположение серверов</div>
            </div>
        </div>
    );
};

export default Map;