import './App.css';
import FirstBlock from "./components/firstBlock/firstBlock";
import Map from "./components/map/map";
import Statistic from "./components/statistic/statistic";
import Info from "./components/info/info";
import React from "react";

function App() {

    return (
        <div className="App">
            <FirstBlock/>
            <Map/>
            <Statistic/>
            <Info/>
        </div>
    );
}

export default App;
